import { Button } from "antd"
import { featureCollection, point } from "@turf/helpers"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import { DownloadOutlined } from "@ant-design/icons"
dayjs.extend(utc)
dayjs.extend(timezone)

const ExportGeoJSONButton = ({
  waypoints,
  timeZone,
  filename,
}: {
  waypoints: {
    latitude: number
    longitude: number
    unix_timestamp: number
    properties: Record<string, unknown>
  }[]
  timeZone: string
  filename: string
}) => {
  return (
    <Button
      onClick={() => {
        const waypointsGeojson = featureCollection(
          waypoints.map((waypoint) =>
            point([waypoint.longitude, waypoint.latitude], {
              timestamp: dayjs
                .unix(waypoint.unix_timestamp)
                .tz(timeZone)
                .format(), // format as ISO 8601 with time zone offset string
              ...waypoint.properties,
            })
          )
        )

        const link = document.createElement("a")
        link.download = `${filename}.geojson`
        link.href = URL.createObjectURL(
          new Blob([JSON.stringify(waypointsGeojson)], {
            type: "application/geo+json",
          })
        )
        link.click()
      }}
    >
      Export GeoJSON <DownloadOutlined />
    </Button>
  )
}

export default ExportGeoJSONButton
