import useFetch from "./useFetch"
import { config } from "./config"
import { getFilteredConnectEvents, getFormattedEvents } from "./Contents.utils"
import { Status, Waypoint } from "./@types/sensordb"
import { Divider, Spin, message } from "antd"
import {
  ConnectEvent,
  ConnectEventType,
  FormattedConnectEvent,
} from "./@types/connect-events"
import { useEffect, useState } from "react"
import Events from "./Events"
import Route, { BaseWaypoints } from "./Route"
import TelemetryData from "./TelemetryData"
import { css } from "vite-css-in-js"
import ExportGeoJSONButton from "./ExportGeoJSONButton"
import ExportCSVButton from "./ExportCSVButton"

const Contents = ({
  connectId,
  timeZone,
  startsAt,
  endsAt,
  isLoadingEvents,
  eventsData,
  eventTypesToShowOnMap,
  highlightedEvent,
  setHighlightedEvent,
}: {
  connectId: string | null
  timeZone: string
  startsAt: string | null
  endsAt: string | null
  isLoadingEvents: boolean
  eventsData?: ConnectEvent[]
  eventTypesToShowOnMap: ConnectEventType[]
  highlightedEvent?: FormattedConnectEvent | null
  setHighlightedEvent: (event: FormattedConnectEvent) => void
}) => {
  const [events, setEvents] = useState<{
    events?: FormattedConnectEvent[]
    eventsForMap?: FormattedConnectEvent[]
  }>()
  useEffect(() => {
    setEvents(
      startsAt && endsAt && eventsData
        ? getFormattedEvents(
            getFilteredConnectEvents(eventsData),
            eventTypesToShowOnMap
          )
        : undefined
    )
  }, [endsAt, eventsData, startsAt, eventTypesToShowOnMap])

  const { isLoading: isLoadingWaypoints, data: waypointsData } = useFetch<
    { waypoints: Waypoint[] },
    { status: string }
  >(
    `${config.SENSORDB_API_HOST}/devices/${connectId}/sensor/waypoints?start_time=${startsAt}&end_time=${endsAt}`,
    {
      disabled: !connectId || !startsAt || !endsAt,
      onError: (error) => {
        message.error("status" in error ? error.status : error.message)
      },
    }
  )

  const {
    isLoading: isLoadingLatestStatus,
    isLoadingInitial: isLoadingInitialLatestStatus,
    data: latestStatus,
    refetch: refetchLatestStatus,
  } = useFetch<Status, { status: string }>(
    `${config.SENSORDB_API_HOST}/devices/${connectId}/status`,
    {
      onError: (error) => {
        message.error("status" in error ? error.status : error.message)
      },
    }
  )

  const homeLocationLatLon = new URLSearchParams(window.location.search)
    .get("home_location")
    ?.split(",")
  const homeLocation = homeLocationLatLon && {
    latitude: parseFloat(homeLocationLatLon[0]),
    longitude: parseFloat(homeLocationLatLon[1]),
  }

  const [allWaypoints, setAllWaypoints] = useState<BaseWaypoints>()
  useEffect(() => {
    if (waypointsData?.waypoints ?? events?.events) {
      let combinedWaypoints: BaseWaypoints | undefined = undefined
      const formattedEvents =
        events?.events?.reduce(
          (acc: BaseWaypoints, { latitude, longitude, ...event }) => {
            if (latitude && longitude) {
              acc.push({
                latitude: latitude,
                longitude: longitude,
                unix_timestamp: Date.parse(event.occurred_at) / 1000, // convert from ISO 8601 to milliseconds to seconds
                properties: event,
              })
            }
            return acc
          },
          []
        ) ?? []
      const formattedWaypoints =
        waypointsData?.waypoints.map(
          ({ latitude, longitude, ...waypoint }) => ({
            latitude: latitude,
            longitude: longitude,
            unix_timestamp: waypoint.utc_timestamp, // Unix timestamp in seconds
            properties: waypoint,
          })
        ) ?? []
      combinedWaypoints = [...formattedEvents, ...formattedWaypoints].sort(
        (a, b) => a.unix_timestamp - b.unix_timestamp
      )
      setAllWaypoints(combinedWaypoints)
    } else {
      setAllWaypoints(undefined)
    }
  }, [events, waypointsData?.waypoints])

  // A lot of the UI depends on loading the latest status, for example to calculate
  // the proper bounding box for the map, so let's wait until it has loaded initially
  // before showing anything
  if (isLoadingInitialLatestStatus || !refetchLatestStatus) return null

  return (
    <>
      <TelemetryData
        connectId={connectId}
        timeZone={timeZone}
        startsAt={startsAt}
        endsAt={endsAt}
        isLoadingLatestStatus={isLoadingLatestStatus}
        latestStatus={latestStatus}
        refetchLatestStatus={refetchLatestStatus}
        homeLocation={homeLocation}
      />
      <Divider />
      <Spin spinning={isLoadingEvents || isLoadingWaypoints}>
        <div
          className={css`
            display: flex;
            gap: 16px;
            flex-direction: column;
            @media (min-width: 768px) {
              flex-direction: row;
            }
          `}
        >
          <div
            style={{
              width: startsAt && endsAt ? "40%" : "100%",
            }}
          >
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 16px;
              `}
            >
              <h2
                className={css`
                  margin-bottom: 0;
                `}
              >
                Route
              </h2>
              {allWaypoints && (
                <ExportGeoJSONButton
                  waypoints={allWaypoints}
                  timeZone={timeZone}
                  filename={`waypoints-connect-${connectId}-${startsAt}-${endsAt}`}
                />
              )}
            </div>
            <Route
              timeZone={timeZone}
              startsAt={startsAt}
              endsAt={endsAt}
              eventsForMap={events?.eventsForMap}
              highlightedEvent={highlightedEvent}
              setHighlightedEvent={setHighlightedEvent}
              waypoints={allWaypoints}
              latestWaypoint={
                latestStatus && {
                  ...latestStatus.coordinate,
                  utc_timestamp: latestStatus.coordinate.updated_at,
                }
              }
              homeLocation={homeLocation}
            />
          </div>
          {startsAt && endsAt && (
            <div
              className={css`
                flex: 1 1 0%;
                padding-bottom: 1rem;
                @media (min-width: 768px) {
                  padding-bottom: 0;
                }
              `}
            >
              <div
                className={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 16px;
                `}
              >
                <h2
                  className={css`
                    margin-bottom: 0;
                  `}
                >
                  Events
                </h2>
                {allWaypoints && (
                  <ExportCSVButton
                    waypoints={allWaypoints}
                    timeZone={timeZone}
                    filename={`waypoints-connect-${connectId}-${startsAt}-${endsAt}`}
                  />
                )}
              </div>
              <Events
                timeZone={timeZone}
                events={events?.events}
                highlightedEvent={highlightedEvent}
                setHighlightedEvent={setHighlightedEvent}
              />
            </div>
          )}
        </div>
      </Spin>
    </>
  )
}

export default Contents
