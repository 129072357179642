// See specs at https://www.notion.so/getaroundconnect/RFC-Bifrost-Event-Stream-7e17a7cea73a401f90136ea07747b288
export enum ConnectEventType {
  Bump = "Bump",
  CarFault = "Car Fault",
  ClearDtc = "Clear DTC",
  CompleteInstall = "Complete Install",
  ConfigChanged = "Config Changed",
  GetFirmwareVersions = "Get Firmware Versions",
  GetFwVersions = "GetFWVersions",
  GlobalShadowUpdate = "Global Shadow Update",
  IgnitionDisabled = "Ignition Disabled",
  IgnitionEnabling = "Ignition Enabling",
  IgnitionOff = "Ignition Off",
  IgnitionOn = "Ignition On",
  IgnitionWithStarterDisabled = "Ignition With Starter Disabled",
  Lock = "Lock",
  LockStarterDisable = "LockStarterDisable",
  Ping = "Ping",
  ProbeOBD2 = "Probe OBD2",
  RadioReset = "Radio Reset",
  Reboot = "Reboot",
  RemoveCanConfig = "Remove CAN Config",
  Scoring = "Scoring",
  SetRecoveryMode = "Set Recovery Mode",
  SetRecoveryModeAlt = "SetRecoveryMode",
  ShadowUpdate = "Shadow Update",
  StarterDisable = "Starter Disable",
  StarterDisableAlt = "StarterDisable",
  StarterEnable = "Starter Enable",
  StarterEnableAlt = "StarterEnable",
  StartOTA = "Start OTA",
  StartOTAAlt = "StartOTA",
  ThebeShutdown = "Thebe Shutdown",
  ToggleHarness = "Toggle Harness",
  TowCancelled = "Tow Cancelled",
  TowResolved = "Tow Resolved",
  TowStart = "Tow Start",
  TowStillHere = "Tow Still Here",
  TowStop = "Tow Stop",
  Unlock = "Unlock",
  UnlockStarterEnable = "UnlockStarterEnable",
  WifiOff = "Wifi Off",
  WifiOn = "Wifi On",
}

export interface ConnectEventBase {
  channel: "Connect" | "Bifrost"
  fuel_percentage: number | null
  is_spirit_user: boolean
  latitude?: number
  location: string
  longitude?: number
  mileage: number
  occurred_at: string
  result: string
  temperature: number | null
  user_id?: string
  user_name: string | null
  voltage: number | null
}

interface ConnectEventBump extends ConnectEventBase {
  event: ConnectEventType.Bump
  backend: {
    car_trip?: "IN_TRIP" | "None"
  }
  data: {
    bump_type: ConnectEventBumpType
    score: number
  }
}

export enum ConnectEventBumpType {
  Moving = "moving",
  Stationary = "stationary",
}

interface ConnectEventOther extends ConnectEventBase {
  data?: never
  event: Exclude<ConnectEventType, ConnectEventType.Bump>
}

export type ConnectEvent = ConnectEventBump | ConnectEventOther

export type FormattedConnectEvent = ConnectEvent & {
  key: string
  pinIndex?: number
}
