import { css } from "vite-css-in-js"
import { Descriptions } from "antd"
import RouteMap from "./RouteMap"
import { celsiusToFahrenheit, getGoogleMapsPositionURL } from "./utils"
import { FormattedConnectEvent } from "./@types/connect-events"
import { Waypoint } from "./@types/sensordb"
import { useEffect, useRef, useState } from "react"

export type BaseWaypoints = {
  latitude: number
  longitude: number
  unix_timestamp: number
  properties: Record<string, unknown>
}[]

const Route = ({
  timeZone,
  startsAt,
  endsAt,
  eventsForMap,
  highlightedEvent,
  setHighlightedEvent,
  waypoints,
  latestWaypoint,
  homeLocation,
}: {
  timeZone: string
  startsAt: string | null
  endsAt: string | null
  eventsForMap?: FormattedConnectEvent[]
  highlightedEvent?: FormattedConnectEvent | null
  setHighlightedEvent: (event: FormattedConnectEvent) => void
  waypoints?: BaseWaypoints
  latestWaypoint?: Waypoint
  homeLocation?: { latitude: number; longitude: number }
}) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const containerRef = useRef<HTMLDivElement>(null)
  const showingEvents = Boolean(startsAt && endsAt)

  useEffect(() => {
    const handleSetDimensions = () => {
      if (containerRef.current) {
        setDimensions({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
        })
      }
    }

    handleSetDimensions()

    window.addEventListener("resize", handleSetDimensions)

    return () => {
      window.removeEventListener("resize", handleSetDimensions)
    }
  }, [containerRef, showingEvents])

  let mapEl
  if (
    ((waypoints != null && waypoints.length > 0) ||
      (latestWaypoint ?? homeLocation)) &&
    dimensions.width &&
    dimensions.height
  ) {
    mapEl = (
      <RouteMap
        events={eventsForMap}
        highlightedEvent={highlightedEvent}
        width={dimensions.width}
        height={dimensions.height}
        waypoints={waypoints}
        timeZone={timeZone}
        setHighlightedEvent={setHighlightedEvent}
        latestWaypoint={latestWaypoint}
        homeLocation={homeLocation}
      />
    )
  } else {
    mapEl = "No Map Data Available"
  }

  return (
    <>
      <div
        className={css`
          position: sticky;
          top: 24px;
        `}
      >
        <div
          style={{
            minHeight: "500px",
            height:
              startsAt && endsAt
                ? "calc(100vh - 591px)"
                : "calc(100vh - 504px)",
          }}
          ref={containerRef}
        >
          {mapEl}
        </div>
        {highlightedEvent && (
          <div
            className={css`
              margin-top: 1rem;
            `}
          >
            <Descriptions
              colon={false}
              layout="vertical"
              labelStyle={{ color: "#7B728E" }}
              column={8}
              className={css`
                border: 1px solid #d9d9d9;
                padding: 8px;
              `}
            >
              <Descriptions.Item label="Location" span={4}>
                {highlightedEvent.latitude && highlightedEvent.longitude ? (
                  <a
                    href={getGoogleMapsPositionURL(
                      highlightedEvent.latitude,
                      highlightedEvent.longitude
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {highlightedEvent.latitude}, {highlightedEvent.longitude}
                  </a>
                ) : (
                  ""
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Fuel">
                {highlightedEvent.fuel_percentage != null
                  ? `${highlightedEvent.fuel_percentage.toFixed()}%`
                  : ""}
              </Descriptions.Item>
              <Descriptions.Item label="Voltage">
                {highlightedEvent.voltage != null
                  ? `${highlightedEvent.voltage.toFixed(2)} V`
                  : ""}
              </Descriptions.Item>
              <Descriptions.Item label="Temperature" span={2}>
                {highlightedEvent.temperature != null
                  ? `${highlightedEvent.temperature.toFixed()} °C | ${celsiusToFahrenheit(
                      highlightedEvent.temperature
                    ).toFixed()} °F`
                  : ""}
              </Descriptions.Item>
            </Descriptions>
          </div>
        )}
      </div>
    </>
  )
}

export default Route
