import { useEffect, useState } from "react"
import Filters from "./Filters"
import Contents from "./Contents"
import Header from "./Header"
import { ConfigProvider, Divider, message } from "antd"
import styles from "./App.module.css"
import {
  ConnectEvent,
  ConnectEventType,
  FormattedConnectEvent,
} from "./@types/connect-events"
import useFetch from "./useFetch"
import { config } from "./config"

const getInputsFromURL = () => {
  const params = new URLSearchParams(window.location.search)
  const connectId = params.get("connect_id")
  // if we don't get a time zone from the URL, then let's use the browser's time zone as the default
  const timeZone =
    params.get("time_zone") ?? Intl.DateTimeFormat().resolvedOptions().timeZone
  // The `start` and `end` are used in chronological order; `end` should always be more recent than `start`
  const endsAt = params.get("end") // the older date
  const startsAt = params.get("start") // the more recent date
  return { connectId, startsAt, endsAt, timeZone }
}

const {
  connectId: connectIdFromURL,
  startsAt: startsAtFromURL,
  endsAt: endsAtFromURL,
  timeZone: timeZoneFromURL,
} = getInputsFromURL()

const App = () => {
  const [connectId, setConnectId] = useState(connectIdFromURL)
  const [timeZone, setTimeZone] = useState(timeZoneFromURL)
  const [endsAt, setEndsAt] = useState(endsAtFromURL)
  const [startsAt, setStartsAt] = useState(startsAtFromURL)
  const [eventTypesToShowOnMap, setEventTypesToShowOnMap] = useState([
    ConnectEventType.IgnitionOn,
    ConnectEventType.IgnitionOff,
    ConnectEventType.TowStart,
    ConnectEventType.TowStop,
    ConnectEventType.Bump,
  ])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    connectId && params.set("connect_id", connectId)
    params.set("time_zone", timeZone)
    if (startsAt) {
      params.set("start", startsAt)
    } else {
      params.delete("start")
    }
    if (endsAt) {
      params.set("end", endsAt)
    } else {
      params.delete("end")
    }
    history.pushState("", "", `?${params.toString()}`)
  }, [connectId, endsAt, startsAt, timeZone])

  useEffect(() => {
    const updateState = () => {
      const { startsAt, endsAt, timeZone } = getInputsFromURL()

      setStartsAt(startsAt)
      setEndsAt(endsAt)
      setTimeZone(timeZone)
    }

    window.addEventListener("popstate", updateState)

    return () => {
      window.removeEventListener("popstate", updateState)
    }
  }, [])

  const [highlightedEvent, setHighlightedEvent] =
    useState<FormattedConnectEvent>()

  const { isLoading: isLoadingEvents, data: eventsData } = useFetch<
    { events: ConnectEvent[] },
    { message: string }
  >(
    `${config.CLAIMS_INVESTIGATION_HOST}/connect/${connectId}/events?start_time=${startsAt}&end_time=${endsAt}`,
    {
      disabled: !connectId || !startsAt || !endsAt,
      onSuccess: () => {
        setHighlightedEvent(undefined)
      },
      onError: (error) => {
        message.error(error.message)
      },
    }
  )

  const eventTypes =
    eventsData &&
    Array.from(
      eventsData.events.reduce(
        (acc, event) => acc.add(event.event),
        new Set<ConnectEventType>()
      )
    ).sort()

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#b01aa7",
          colorLink: "#b01aa7",
          colorTextBase: "#262626",
        },
        components: {
          Descriptions: {
            itemPaddingBottom: 0,
          },
        },
      }}
    >
      <div className={styles.app}>
        <Header />
        <Divider />
        <Filters
          connectId={connectId}
          setConnectId={setConnectId}
          timeZone={timeZone}
          setTimeZone={setTimeZone}
          startsAt={startsAt}
          setStartsAt={setStartsAt}
          endsAt={endsAt}
          setEndsAt={setEndsAt}
          eventTypes={eventTypes}
          eventTypesToShowOnMap={eventTypesToShowOnMap}
          setEventTypesToShowOnMap={setEventTypesToShowOnMap}
        />
        <Divider />
        {connectId && (
          <Contents
            connectId={connectId}
            startsAt={startsAt}
            endsAt={endsAt}
            timeZone={timeZone}
            isLoadingEvents={isLoadingEvents}
            eventsData={eventsData?.events}
            eventTypesToShowOnMap={eventTypesToShowOnMap}
            highlightedEvent={highlightedEvent}
            setHighlightedEvent={setHighlightedEvent}
          />
        )}
      </div>
    </ConfigProvider>
  )
}

export default App
