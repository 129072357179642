// e.g. Fri, Dec 15, 2023, 1:45 PM PST
export const FULL_DATE: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
  weekday: "short",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  timeZoneName: "short",
}

/**
 * Return a URL to Google Maps following their recommended programmatic structure
 * @param latitude
 * @param longitude
 */
export function getGoogleMapsPositionURL(
  latitude: number | string,
  longitude: number | string
) {
  return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
}

/**
 * Return a URL to Google Maps following their recommended programmatic structure
 * @param latitude
 * @param longitude
 */
export function getGoogleMapsDirectionsURL(
  origin: {
    latitude: number | string
    longitude: number | string
  },
  destination: {
    latitude: number | string
    longitude: number | string
  }
) {
  return `https://www.google.com/maps/dir/?api=1&origin=${origin.latitude},${origin.longitude}&destination=${destination.latitude},${destination.longitude}&travelmode=driving`
}

export const celsiusToFahrenheit = (t: number) => t * (9 / 5) + 32

export const titleCase = (s: string) =>
  s
    .toLocaleLowerCase()
    .replace(/^[-_]*(.)/, (_, c: string) => c.toUpperCase()) // Initial char (after -/_)
    .replace(/[-_]+(.)/g, (_, c: string) => " " + c.toUpperCase()) // First char after each -/_
