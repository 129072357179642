import { Button } from "antd"
import { css } from "vite-css-in-js"
import GetaroundStamp from "./assets/getaround-stamp.svg"

const Header = () => {
  return (
    <div
      className={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <h1
        className={css`
          display: flex;
          align-items: center;
          gap: 16px;
          margin: 0;
        `}
      >
        <img
          src={GetaroundStamp}
          className={css`
            width: 54px;
            height: 54px;
          `}
        />
        Connect Tracking
      </h1>
      <div>
        <Button href="/logout">Log out</Button>
      </div>
    </div>
  )
}

export default Header
