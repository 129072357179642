import { css } from "vite-css-in-js"
import { Table } from "antd"
import { getGoogleMapsPositionURL, titleCase } from "./utils"
import styles from "./Events.module.css"
import routeMapStyles from "./RouteMap.module.css"
import {
  ConnectEventType,
  FormattedConnectEvent,
} from "./@types/connect-events"

const Events = ({
  timeZone,
  events,
  highlightedEvent,
  setHighlightedEvent,
}: {
  timeZone: string
  events?: FormattedConnectEvent[]
  highlightedEvent?: FormattedConnectEvent | null
  setHighlightedEvent: (event: FormattedConnectEvent) => void
}) => {
  if (!events || events.length === 0) {
    return "No events occurred"
  }

  return (
    <Table
      size="small"
      dataSource={events}
      columns={[
        {
          dataIndex: "pinIndex",
          width: 26,
          render: (pinIndex, event) => {
            const isCurrentEventHighlighted =
              highlightedEvent?.occurred_at === event.occurred_at
            if (event.pinIndex != null || isCurrentEventHighlighted) {
              return (
                <div
                  className={`${routeMapStyles.eventCircle} ${
                    isCurrentEventHighlighted
                      ? routeMapStyles.eventCircleHighlighted
                      : ""
                  }`}
                >
                  {pinIndex}
                </div>
              )
            }
            return (
              <div
                className={css`
                  width: 20;
                `}
              />
            )
          },
        },
        {
          title: "Timestamp",
          dataIndex: "occurred_at",
          render: (occurredAt: string) =>
            new Date(occurredAt).toLocaleDateString(undefined, {
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              timeZoneName: "short",
              timeZone: timeZone,
            }),
        },
        {
          title: "Operation",
          dataIndex: "event",
        },
        {
          title: "Channel",
          dataIndex: "channel",
        },
        {
          title: "Result",
          key: "result",
          render: (event: FormattedConnectEvent) =>
            event.event === ConnectEventType.Bump ? (
              `Score: ${Math.round(event.data.score)}`
            ) : (
              <span
                style={{
                  color: event.result === "COMPLETE" ? "green" : "red",
                }}
              >
                {titleCase(event.result)}
              </span>
            ),
        },
        {
          title: "User",
          dataIndex: "user_id",
        },
        {
          title: "Fuel",
          dataIndex: "fuel_percentage",
          render: (fuelPercentage: number | null) =>
            fuelPercentage != null ? `${fuelPercentage.toFixed()}%` : "",
        },
        {
          title: "Voltage",
          dataIndex: "voltage",
          render: (voltage: number | null) => {
            if (voltage == null) {
              return ""
            }
            let color: string
            if (voltage >= 12.6) {
              color = "green"
            } else if (voltage >= 12) {
              color = "goldenrod"
            } else {
              color = "red"
            }
            return <span style={{ color }}>{voltage.toFixed(2)} V</span>
          },
        },
        {
          title: "Distance (km)",
          dataIndex: "mileage",
          render: (mileage, _, index) => {
            const prevRecord = events.at(index + 1)
            if (prevRecord?.mileage != null) {
              const diff = mileage - prevRecord.mileage
              return diff === 0 ? 0 : (diff / 1000).toFixed(1)
            }
            return null
          },
        },
        {
          title: "Map",
          key: "location",
          render: ({ latitude, longitude }: FormattedConnectEvent) =>
            latitude && longitude ? (
              <a
                href={getGoogleMapsPositionURL(latitude, longitude)}
                target="_blank"
                rel="noopener noreferrer"
              >
                View
              </a>
            ) : (
              ""
            ),
        },
      ]}
      rowClassName={(connectEvent) =>
        highlightedEvent &&
        connectEvent.occurred_at === highlightedEvent.occurred_at
          ? styles.highlightedRow
          : ""
      }
      onRow={(connectEvent) => ({
        onMouseEnter: () => {
          setHighlightedEvent(connectEvent)
        },
      })}
      pagination={false}
      tableLayout="auto"
      bordered
      className={styles.table}
    />
  )
}

export default Events
