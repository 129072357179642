import { useEffect, useRef, useState } from "react"

const useFetch = <DataType, ErrorType = unknown>(
  resource: RequestInfo | URL,
  options?: RequestInit,
  extra?: {
    disabled?: boolean
    onSuccess?: (data?: DataType) => void
    onError?: (error: ErrorType | Error) => void
  }
) => {
  const { disabled = false, onSuccess, onError } = extra ?? {}

  const [isLoading, setIsLoading] = useState(false)
  const [hasBeenCalled, setHasBeenCalled] = useState(false)
  const [data, setData] = useState<DataType>()
  const [error, setError] = useState<ErrorType | Error>()
  const fetchFunc = useRef<() => Promise<void>>()

  useEffect(() => {
    const abortController = new AbortController()

    const initiateFetch = async () => {
      try {
        setIsLoading(true)

        const res = await fetch(resource, {
          ...options,
          signal: abortController.signal,
        })
        const json = (await res.json()) as unknown

        setIsLoading(false)
        setHasBeenCalled(true)

        if (!res.ok) {
          setData(undefined)
          setError(json as ErrorType)
          onError?.(json as ErrorType)
        } else {
          setError(undefined)
          setData(json as DataType)
          onSuccess?.(json as DataType)
        }
      } catch (error) {
        if ((error as Error).name !== "AbortError") {
          setIsLoading(false)
          setHasBeenCalled(true)

          setError(error as Error)
          onError?.(error as Error)
        }
      }
    }
    fetchFunc.current = initiateFetch
    if (disabled) {
      setData(undefined)
      setError(undefined)
      onSuccess?.()
    } else {
      initiateFetch()
    }

    return () => {
      abortController.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource, disabled])

  return {
    isLoading,
    isLoadingInitial: isLoading && !hasBeenCalled,
    data,
    error,
    refetch: fetchFunc.current,
  }
}

export default useFetch
