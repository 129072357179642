import {
  ConnectEvent,
  ConnectEventBumpType,
  ConnectEventType,
} from "./@types/connect-events"

export const getFilteredConnectEvents = (connectEvents: ConnectEvent[]) =>
  connectEvents.filter((connectEvent) => {
    if (
      [
        ConnectEventType.CompleteInstall,
        ConnectEventType.ConfigChanged,
        ConnectEventType.GlobalShadowUpdate,
        ConnectEventType.Ping,
        ConnectEventType.ProbeOBD2,
        ConnectEventType.RadioReset,
        ConnectEventType.ShadowUpdate,
        ConnectEventType.StartOTA,
        ConnectEventType.WifiOff,
        ConnectEventType.WifiOn,
      ].includes(connectEvent.event)
    ) {
      return false
    }
    if (connectEvent.event === ConnectEventType.Bump) {
      if (
        connectEvent.data.bump_type === ConnectEventBumpType.Moving &&
        ((connectEvent.backend.car_trip === "IN_TRIP" &&
          connectEvent.data.score >= 20) ||
          connectEvent.data.score >= 50)
      ) {
        // Only keep specific Bump events; namely if they are moving bumps and either:
        // - it happened within a trip and the score is 20 or more, or
        // - it happened outside of a trip and the score is 50 or more
        return true
      }

      // Reject all other Bump events
      return false
    }

    // Keep all other events that are not Bump events
    return true
  })

export const getFormattedEvents = (
  events: ConnectEvent[],
  eventTypesToShowOnMap: ConnectEventType[]
) => {
  const shouldEventBeOnMap = ({ location, event: eventType }: ConnectEvent) =>
    Boolean(location) && eventTypesToShowOnMap.includes(eventType)

  let pinIndex = events.filter(shouldEventBeOnMap).length

  const relevantEvents = events.map((event) => {
    const { location } = event

    let latitude
    let longitude
    if (location) {
      ;[latitude, longitude] = location.split(",")
      latitude = parseFloat(latitude)
      longitude = parseFloat(longitude)
    }

    const baseProperties = {
      ...event,
      key: `event_${event.occurred_at}`,
      latitude,
      longitude,
    }

    let eventPinIndex
    if (shouldEventBeOnMap(event)) {
      eventPinIndex = pinIndex
      pinIndex -= 1
    }

    return {
      ...baseProperties,
      pinIndex: eventPinIndex,
    }
  })
  const eventsForMap = relevantEvents.filter(shouldEventBeOnMap)

  return {
    events: relevantEvents,
    eventsForMap,
  }
}
