import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import { DatePicker, Form, Input, Select, Space } from "antd"
import { css } from "vite-css-in-js"
import { ConnectEventType } from "./@types/connect-events"
dayjs.extend(utc)
dayjs.extend(timezone)

const Filters = ({
  connectId,
  setConnectId,
  timeZone,
  setTimeZone,
  startsAt,
  setStartsAt,
  endsAt,
  setEndsAt,
  eventTypes,
  eventTypesToShowOnMap,
  setEventTypesToShowOnMap,
}: {
  connectId: string | null
  setConnectId: (newConnectId: string | null) => void
  timeZone: string
  setTimeZone: (newTimeZone: string) => void
  startsAt: string | null
  setStartsAt: (newStartsAt: string | null) => void
  endsAt: string | null
  setEndsAt: (newEndsAt: string | null) => void
  eventTypes?: ConnectEventType[]
  eventTypesToShowOnMap: ConnectEventType[]
  setEventTypesToShowOnMap: (newEventTypes: ConnectEventType[]) => void
}) => (
  <div>
    <h2>Filters</h2>
    <Form layout="vertical">
      <Space size="large">
        <Form.Item
          label="Connect ID (for 1P) or VIN (for Native):"
          className={css`
            min-width: 220px;
            margin: 0;
          `}
        >
          <Input.Search
            className={css`
              width: 100%;
            `}
            enterButton="Go"
            defaultValue={connectId ?? undefined}
            onSearch={(newConnectId) => {
              setConnectId(newConnectId)
            }}
          />
        </Form.Item>
        <Form.Item
          label="Historical events and waypoints date range:"
          className={css`
            margin: 0;
          `}
        >
          <DatePicker.RangePicker
            showTime
            allowEmpty={[true, true]}
            presets={[
              {
                label: "Last day",
                value: [
                  dayjs()
                    .subtract(1, "days")
                    .tz(timeZone)
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0),
                  dayjs().tz(timeZone),
                ],
              },
              {
                label: "Last 3 days",
                value: [
                  dayjs()
                    .subtract(3, "days")
                    .tz(timeZone)
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0),
                  dayjs().tz(timeZone),
                ],
              },
              {
                label: "Last 15 days",
                value: [
                  dayjs()
                    .subtract(15, "days")
                    .tz(timeZone)
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0),
                  dayjs().tz(timeZone),
                ],
              },
              {
                label: "Last 30 days",
                value: [
                  dayjs()
                    .subtract(30, "days")
                    .tz(timeZone)
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0),
                  dayjs().tz(timeZone),
                ],
              },
            ]}
            value={[
              startsAt ? dayjs(startsAt).tz(timeZone) : null,
              endsAt ? dayjs(endsAt).tz(timeZone) : null,
            ]}
            onCalendarChange={(newDates) => {
              setStartsAt(newDates?.[0]?.toISOString() ?? null)
              setEndsAt(newDates?.[1]?.toISOString() ?? null)
            }}
            // don't allow selecting dates in the future
            disabledDate={(currentDate) =>
              currentDate.toISOString() > new Date().toISOString()
            }
          />
        </Form.Item>
        <Form.Item
          label="Time zone:"
          className={css`
            margin: 0;
          `}
        >
          <Select
            style={{ width: 200 }}
            options={[{ label: "UTC", value: "utc" }].concat(
              Intl.supportedValuesOf("timeZone").map((timeZone: string) => ({
                value: timeZone,
                label: timeZone,
              }))
            )}
            value={timeZone}
            showSearch
            onChange={(newTimeZone) => {
              setTimeZone(newTimeZone)
            }}
          />
        </Form.Item>

        {eventTypes && (
          <Form.Item
            label="Show on map"
            className={css`
              margin: 0;
            `}
          >
            <Select
              mode="multiple"
              allowClear
              maxTagCount="responsive"
              style={{ width: 200 }}
              value={eventTypesToShowOnMap}
              onChange={setEventTypesToShowOnMap}
              options={eventTypes.map((type) => ({
                value: type,
                label: type,
              }))}
            />
          </Form.Item>
        )}
      </Space>
    </Form>
  </div>
)

export default Filters
