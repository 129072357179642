import useFetch from "./useFetch"
import { config } from "./config"
import { Status, Distance } from "./@types/sensordb"
import { Button, Descriptions, Switch, message } from "antd"
import { css } from "vite-css-in-js"
import { useEffect, useState } from "react"
import mbxDirectionsClient, {
  Route,
} from "@mapbox/mapbox-sdk/services/directions"
import { getGoogleMapsDirectionsURL, getGoogleMapsPositionURL } from "./utils"
import { ReloadOutlined } from "@ant-design/icons"
import { FULL_DATE } from "./utils"
import { DescriptionsItemType } from "antd/es/descriptions"

const TelemetryData = ({
  connectId,
  timeZone,
  startsAt,
  endsAt,
  isLoadingLatestStatus,
  latestStatus,
  refetchLatestStatus,
  homeLocation,
}: {
  connectId: string | null
  timeZone: string
  startsAt: string | null
  endsAt: string | null
  isLoadingLatestStatus: boolean
  latestStatus?: Status
  refetchLatestStatus: () => Promise<void>
  homeLocation?: { latitude: number; longitude: number }
}) => {
  const { data: distance } = useFetch<Distance, { status: string }>(
    `${config.SENSORDB_API_HOST}/devices/${connectId}/sensor/waypoints:distance?start_time=${startsAt}&end_time=${endsAt}`,
    {
      disabled: !connectId || !startsAt || !endsAt,
      onError: (error) => {
        message.error("status" in error ? error.status : error.message)
      },
    }
  )

  const [distanceToHomeLocation, setDistanceToHomeLocation] =
    useState<Route<string>>()
  useEffect(() => {
    if (
      homeLocation?.latitude &&
      homeLocation.longitude &&
      latestStatus?.coordinate
    ) {
      mbxDirectionsClient({ accessToken: config.MAPBOX_API_KEY })
        .getDirections({
          profile: "driving-traffic",
          waypoints: [
            {
              coordinates: [
                latestStatus.coordinate.longitude,
                latestStatus.coordinate.latitude,
              ],
            },
            { coordinates: [homeLocation.longitude, homeLocation.latitude] },
          ],
        })
        .send()
        .then((response) => {
          setDistanceToHomeLocation(response.body.routes[0])
        })
    }
  }, [
    homeLocation?.latitude,
    homeLocation?.longitude,
    latestStatus?.coordinate,
  ])

  const [autorefreshIntervalID, setAutorefreshIntervalID] =
    useState<NodeJS.Timeout>()

  const items: DescriptionsItemType[] = []

  if (latestStatus?.coordinate) {
    items.push({
      key: "currentLocation",
      span: 2,
      label: "Current location",
      children: (
        <a
          href={getGoogleMapsPositionURL(
            latestStatus.coordinate.latitude,
            latestStatus.coordinate.longitude
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          {latestStatus.coordinate.latitude.toFixed(6)},{" "}
          {latestStatus.coordinate.longitude.toFixed(6)}
        </a>
      ),
    })
  }

  if (homeLocation) {
    items.push({
      key: "homeLocation",
      span: 2,
      label: "Home location",
      children: (
        <a
          href={getGoogleMapsPositionURL(
            homeLocation.latitude,
            homeLocation.longitude
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          {homeLocation.latitude}, {homeLocation.longitude}
        </a>
      ),
    })
  }

  if (latestStatus?.coordinate && homeLocation && distanceToHomeLocation) {
    items.push({
      key: "timeToHomeLocation",
      span: 2,
      label: "Time to home location",
      children: (
        <>
          <a
            href={getGoogleMapsDirectionsURL(
              {
                latitude: latestStatus.coordinate.latitude,
                longitude: latestStatus.coordinate.longitude,
              },
              {
                latitude: homeLocation.latitude,
                longitude: homeLocation.longitude,
              }
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            ~{Math.round(distanceToHomeLocation.duration / 60)} min (
            {(distanceToHomeLocation.distance / 1000).toFixed(2)} km |{" "}
            {(distanceToHomeLocation.distance / 1609).toFixed(2)} mi)
          </a>
        </>
      ),
    })
  }

  if (distance) {
    items.push({
      key: "distanceTraveled",
      span: 2,
      label: "Distance traveled [est]",
      children: (
        <>
          {distance.distance_km.toFixed(2)} km |{" "}
          {distance.distance_mi.toFixed(2)} mi
        </>
      ),
    })
  }

  if (latestStatus?.fuel_level != null) {
    items.push({
      key: "fuel",
      label: "Fuel",
      children: `${(latestStatus.fuel_level / 10).toFixed()}%`,
    })
  }

  if (latestStatus?.board_voltage != null) {
    let color: string
    if (latestStatus.board_voltage >= 12.6) {
      color = "green"
    } else if (latestStatus.board_voltage >= 12) {
      color = "goldenrod"
    } else {
      color = "red"
    }
    items.push({
      key: "voltage",
      label: "Voltage",
      children: (
        <span style={{ color }}>{latestStatus.board_voltage.toFixed(2)} V</span>
      ),
    })
  }

  if (latestStatus?.ignition_on != null) {
    items.push({
      key: "ignition",
      label: "Ignition",
      children: latestStatus.ignition_on ? (
        <span
          className={css`
            color: green;
          `}
        >
          ON
        </span>
      ) : (
        <span
          className={css`
            color: red;
          `}
        >
          OFF
        </span>
      ),
    })
  }

  return (
    <div>
      <div
        className={css`
          display: flex;
          align-items: center;
          gap: 16px;
        `}
      >
        <h2>Telemetry Data</h2>
        <div
          className={css`
            display: flex;
            align-items: center;
            gap: 16px;
            font-size: 14px;
          `}
        >
          {latestStatus && (
            <div>
              As of{" "}
              <strong
                className={css`
                  font-weight: bold;
                  color: #595959;
                `}
              >
                {new Date(latestStatus.updated_at * 1000).toLocaleString(
                  undefined,
                  { ...FULL_DATE, timeZone: timeZone }
                )}
              </strong>
            </div>
          )}
          <Button
            size="middle"
            shape="circle"
            onClick={() => {
              refetchLatestStatus()
            }}
            disabled={isLoadingLatestStatus}
          >
            <ReloadOutlined />
          </Button>
          <div>
            <Switch
              onChange={(checked) => {
                if (checked) {
                  // when the user enables autorefresh, refresh immediately, then every minute afterwards
                  refetchLatestStatus()
                  const intervalID = setInterval(() => {
                    refetchLatestStatus()
                  }, 60000)
                  setAutorefreshIntervalID(intervalID)
                } else if (autorefreshIntervalID) {
                  clearTimeout(autorefreshIntervalID)
                }
              }}
            />{" "}
            Autorefresh
          </div>
        </div>
      </div>

      <Descriptions
        colon={false}
        layout="vertical"
        labelStyle={{ color: "#7B728E" }}
        column={11}
        items={items}
        className={css`
          border: 1px solid #d9d9d9;
          padding: 8px;
        `}
      />
    </div>
  )
}

export default TelemetryData
