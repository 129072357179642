import { useEffect, useState } from "react"
import ReactMapGL, {
  Source,
  Layer,
  Marker,
  NavigationControl,
  FullscreenControl,
  WebMercatorViewport,
} from "react-map-gl"
import { css } from "vite-css-in-js"
import { Descriptions, Tag, Tooltip } from "antd"
import { HomeOutlined } from "@ant-design/icons"
import bbox from "@turf/bbox"
import { lineString, multiPoint } from "@turf/helpers"
import CarIcon from "./assets/car-icon.svg"
import {
  FULL_DATE,
  celsiusToFahrenheit,
  getGoogleMapsPositionURL,
  titleCase,
} from "./utils"
import {
  ConnectEventType,
  FormattedConnectEvent,
} from "./@types/connect-events"
import styles from "./RouteMap.module.css"
import { config } from "./config"
import { Waypoint } from "./@types/sensordb"

const maxInitialZoom = 14

const RouteMap = ({
  width,
  height,
  waypoints,
  timeZone,
  events = [],
  highlightedEvent,
  setHighlightedEvent,
  latestWaypoint,
  homeLocation,
}: {
  height: number
  width: number
  waypoints?: { latitude: number; longitude: number }[]
  timeZone?: string
  events?: FormattedConnectEvent[]
  highlightedEvent?: FormattedConnectEvent | null
  setHighlightedEvent?: (newHighlightedEvent: FormattedConnectEvent) => void
  latestWaypoint?: Waypoint
  homeLocation?: { latitude: number; longitude: number }
}) => {
  const [viewport, setViewport] = useState<{
    width: number
    height: number
    longitude: number
    latitude: number
    // Make sure we don't zoom in too much and can still see city streets for scale
    zoom: number
  }>()
  // Initialize the viewport to fit the entire waypoints shape inside
  useEffect(() => {
    const allMarkers =
      waypoints?.map(({ latitude, longitude }) => [longitude, latitude]) ?? []
    if (latestWaypoint)
      allMarkers.push([latestWaypoint.longitude, latestWaypoint.latitude])
    if (homeLocation)
      allMarkers.push([homeLocation.longitude, homeLocation.latitude])
    const geojson = multiPoint(allMarkers)
    const [minLng, minLat, maxLng, maxLat] = bbox(geojson)
    const wmv = new WebMercatorViewport({ width, height })
    const { longitude, latitude, zoom } = wmv.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      { padding: waypoints ? 30 : 75 }
    )
    setViewport({
      width,
      height,
      longitude,
      latitude,
      // Make sure we don't zoom in too much and can still see city streets for scale
      zoom: Math.min(zoom, maxInitialZoom),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height, waypoints])

  const [scrollZoom, setScrollZoom] = useState(false)

  const waypointsGeojson = waypoints?.length
    ? lineString(
        waypoints.map(({ latitude, longitude }) => [longitude, latitude])
      )
    : undefined
  const startMarkerWaypoint = waypointsGeojson?.geometry.coordinates[0]
  const endMarkerWaypoint =
    waypointsGeojson?.geometry.coordinates[
      waypointsGeojson.geometry.coordinates.length - 1
    ]

  if (
    highlightedEvent &&
    !events.some((event) => event.key === highlightedEvent.key)
  ) {
    events = events.concat(highlightedEvent)
  }

  if (!viewport) return

  return (
    <div
      onMouseLeave={() => {
        setScrollZoom(false)
      }}
    >
      <ReactMapGL
        {...viewport}
        width={`${width}px`}
        height={`${height}px`}
        onViewportChange={setViewport}
        scrollZoom={scrollZoom}
        onMouseDown={() => {
          setScrollZoom(true)
        }}
        mapStyle="mapbox://styles/getaround-mapbox/cj1s70uhh00152so6zone9vmb"
        mapboxApiAccessToken={config.MAPBOX_API_KEY}
      >
        {waypointsGeojson && (
          <Source
            id="waypoints"
            type="geojson"
            data={waypointsGeojson}
            lineMetrics
          >
            <Layer
              id="waypoints-line"
              type="line"
              layout={{
                "line-join": "round",
                "line-cap": "round",
              }}
              paint={{
                "line-width": 3,
                "line-gradient": [
                  "interpolate",
                  ["linear"],
                  ["line-progress"],
                  0,
                  "#5ECBDD",
                  1,
                  "#B01AA7",
                ],
              }}
            />
          </Source>
        )}
        {events.map((event) => {
          const {
            location,
            latitude,
            longitude,
            occurred_at,
            user_id,
            event: eventType,
            result,
            pinIndex,
            voltage,
            fuel_percentage,
            temperature,
            data,
          } = event
          const isCurrentEventHighlighted =
            highlightedEvent?.occurred_at === occurred_at

          if (!latitude || !longitude) return null

          return (
            <Marker
              key={occurred_at}
              latitude={latitude}
              longitude={longitude}
              // Make sure the highlighted pin is shown above all other pins
              className={`${
                isCurrentEventHighlighted
                  ? css`
                      z-index: 1010;
                    `
                  : ""
              }`}
            >
              <Tooltip
                title={
                  <>
                    <div
                      className={css`
                        font-size: 18px;
                        margin-bottom: 8px;
                      `}
                    >
                      {eventType}{" "}
                      <Tag
                        className={css`
                          position: absolute;
                          right: 0;
                        `}
                      >
                        {eventType === ConnectEventType.Bump && data.score
                          ? `Score: ${Math.round(data.score)}`
                          : titleCase(result)}
                      </Tag>
                    </div>
                    <Descriptions column={1} size="small">
                      <Descriptions.Item>
                        {new Date(occurred_at).toLocaleDateString(undefined, {
                          timeZone: timeZone,
                          ...FULL_DATE,
                        })}
                      </Descriptions.Item>
                      {location ? (
                        <Descriptions.Item>
                          <a
                            href={getGoogleMapsPositionURL(latitude, longitude)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {latitude}, {longitude}
                          </a>
                        </Descriptions.Item>
                      ) : null}
                      <Descriptions.Item label="User">
                        {user_id}
                      </Descriptions.Item>
                      {voltage != null && (
                        <Descriptions.Item label="Voltage">
                          {`${voltage.toFixed(2)} V`}
                        </Descriptions.Item>
                      )}
                      {fuel_percentage != null && (
                        <Descriptions.Item label="Fuel">
                          {`${fuel_percentage.toFixed()}%`}
                        </Descriptions.Item>
                      )}
                      {temperature != null && (
                        <Descriptions.Item label="Temperature">
                          {`${temperature.toFixed()} °C | ${celsiusToFahrenheit(
                            temperature
                          ).toFixed()} °F`}
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  </>
                }
                getPopupContainer={(markerDOMElement) =>
                  markerDOMElement.closest(".overlays") ?? markerDOMElement
                }
              >
                <div
                  className={`${styles.eventPin} ${
                    isCurrentEventHighlighted ? styles.eventPinHighlighted : ""
                  }`}
                  style={{}}
                  onMouseEnter={() => {
                    if (setHighlightedEvent) {
                      setHighlightedEvent(event)
                    }
                  }}
                >
                  {pinIndex}
                </div>
              </Tooltip>
            </Marker>
          )
        })}
        {startMarkerWaypoint && (
          <Marker
            longitude={startMarkerWaypoint[0]}
            latitude={startMarkerWaypoint[1]}
          >
            <div
              className={`${styles.carPin} ${css`
                background-color: #5ecbdd;
              `}`}
            >
              <Tooltip
                title="Start"
                getPopupContainer={(markerDOMElement) =>
                  markerDOMElement.closest(".overlays") ?? markerDOMElement
                }
              >
                <img src={CarIcon} />
              </Tooltip>
            </div>
          </Marker>
        )}
        {endMarkerWaypoint && (
          <Marker
            longitude={endMarkerWaypoint[0]}
            latitude={endMarkerWaypoint[1]}
          >
            <div
              className={`${styles.carPin} ${css`
                background-color: #b01aa7;
              `}`}
            >
              <Tooltip
                title="End"
                getPopupContainer={(markerDOMElement) =>
                  markerDOMElement.closest(".overlays") ?? markerDOMElement
                }
              >
                <img src={CarIcon} />
              </Tooltip>
            </div>
          </Marker>
        )}
        {homeLocation && (
          <Marker
            longitude={homeLocation.longitude}
            latitude={homeLocation.latitude}
          >
            <div
              className={`${styles.carPin} ${css`
                background-color: #a49da7;
              `}`}
            >
              <Tooltip
                title="Home location"
                getPopupContainer={(markerDOMElement) =>
                  markerDOMElement.closest(".overlays") ?? markerDOMElement
                }
              >
                <HomeOutlined />
              </Tooltip>
            </div>
          </Marker>
        )}
        {latestWaypoint && (
          <Marker
            longitude={latestWaypoint.longitude}
            latitude={latestWaypoint.latitude}
          >
            <div
              className={`${styles.carPin} ${css`
                background-color: #b20000;
              `}`}
            >
              <Tooltip
                title={`Current car location as of ${new Date(
                  latestWaypoint.utc_timestamp * 1000
                ).toLocaleString(undefined, FULL_DATE)}`}
                getPopupContainer={(markerDOMElement) =>
                  markerDOMElement.closest(".overlays") ?? markerDOMElement
                }
              >
                <img src={CarIcon} />
              </Tooltip>
            </div>
          </Marker>
        )}
        <FullscreenControl
          className={css`
            position: absolute;
            top: 0;
            right: 0;
            padding: 8px;
          `}
        />
        <NavigationControl
          className={css`
            position: absolute;
            top: 0;
            left: 0;
            padding: 8px;
          `}
          showCompass={false}
        />
      </ReactMapGL>
    </div>
  )
}

export default RouteMap
