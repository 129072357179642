import { Button } from "antd"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import { getGoogleMapsPositionURL } from "./utils"
import { DownloadOutlined } from "@ant-design/icons"
dayjs.extend(utc)
dayjs.extend(timezone)

// https://stackoverflow.com/a/55251598/3023040
const flattenObject = (
  obj: Record<string, unknown>
): Record<string, unknown> => {
  const flattened: Record<string, unknown> = {}

  Object.keys(obj).forEach((key) => {
    const value = obj[key]

    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value as Record<string, unknown>))
    } else {
      flattened[key] = value
    }
  })

  return flattened
}

const ExportCSVButton = ({
  waypoints,
  timeZone,
  filename,
}: {
  waypoints: {
    latitude: number
    longitude: number
    unix_timestamp: number
    properties: Record<string, unknown>
  }[]
  timeZone: string
  filename: string
}) => {
  return (
    <Button
      onClick={() => {
        const csvHeaders = Object.keys(
          waypoints.reduce((acc, waypoint) => {
            acc = { ...acc, ...flattenObject(waypoint.properties) }
            return acc
          }, {})
        )
        const waypointsCSV = waypoints.reduce((acc, waypoint) => {
          acc += `${dayjs
            .unix(waypoint.unix_timestamp)
            .tz(timeZone)
            .format()},${waypoint.latitude},${
            waypoint.longitude
          },"${getGoogleMapsPositionURL(
            waypoint.latitude,
            waypoint.longitude
          )}"`
          const flattenedProperties = flattenObject(waypoint.properties)
          csvHeaders.forEach((header) => {
            const value = flattenedProperties[header]
            acc += `,${value != null ? JSON.stringify(value) : ""}`
          })
          return `${acc}\n`
        }, `datetime,latitude,longitude,Google Maps,${csvHeaders.join(",")}\n`)

        const link = document.createElement("a")
        link.download = `${filename}.csv`
        link.href = URL.createObjectURL(
          new Blob([waypointsCSV], {
            type: "text/csv",
          })
        )
        link.click()
      }}
    >
      Export CSV <DownloadOutlined />
    </Button>
  )
}

export default ExportCSVButton
